<template>
  <div id="app" ref="app">
    <router-view v-wechat-title="$route.meta.title"></router-view>
  </div>
</template>

<script>
// import DevicePixelRatio from "./utils/devicePixelRatio";
// 用jq适配
// import $ from 'jquery'
// 用lodash插件
// import _ from "lodash";

export default {
  name: "App",
  data() {
    return {};
  },
  methods: {
    // detectZoom() {
    //   var ratio = 0,
    //     screen = window.screen,
    //     ua = navigator.userAgent.toLowerCase();
    //   if (window.devicePixelRatio !== undefined) {
    //     ratio = window.devicePixelRatio;
    //   } else if (~ua.indexOf("msie")) {
    //     if (screen.deviceXDPI && screen.logicalXDPI) {
    //       ratio = screen.deviceXDPI / screen.logicalXDPI;
    //     }
    //   } else if (
    //     window.outerWidth !== undefined &&
    //     window.innerWidth !== undefined
    //   ) {
    //     ratio = window.outerWidth / window.innerWidth;
    //   }
    //   if (ratio) {
    //     ratio = Math.round(ratio * 100);
    //   }
    //   //ratio就是获取到的百分比
    //   console.log(ratio);
    //   this.onresize_height = ratio;
    //   // return ratio;
    // },
    // 用jq适配
    // selfAdaption() {
    //   this.windowHeight = document.documentElement.clientHeight;
    //   this.windowWidth = document.documentElement.clientWidth;
    //   let heightScale = this.windowHeight / 700;
    //   let widthScale = this.windowWidth / 1536;
    //   $('body').css({
    //     transform: `scale(${widthScale},${heightScale})`,
    //     "-ms-transform": `scale(${widthScale},${heightScale})`,
    //     "-webkit-transform": `scale(${widthScale},${heightScale})`,
    //   });
    //   window.onresize = () => {
    //     //屏幕尺寸改变时触发
    //     this.windowHeight = document.documentElement.clientHeight;
    //     this.windowWidth = document.documentElement.clientWidth;
    //     let heightScale = this.windowHeight / 1080;
    //     let widthScale = this.windowWidth / 1920;
    //     $('body').css({
    //       transform: `scale(${widthScale},${heightScale})`,
    //       "-ms-transform": `scale(${widthScale},${heightScale})`,
    //       "-webkit-transform": `scale(${widthScale},${heightScale})`,
    //     });
    //   };
    // },
  },
  created() {
    // new DevicePixelRatio().init();
  },
  mounted() {
    // window.onresize = () => {
    //   return (() => {
    //     this.detectZoom();
    //   })();
    // };


    // 用jq适配
    // this.selfAdaption()


    // 用lodash插件
    // this.$nextTick(() => {
    //   const $app = this.$refs.app
    //   const standardScale = 902 / 1920
    //   window.addEventListener(
    //     'resize',
    //     _.debounce(function () {
    //       const docHeight = document.body.clientHeight
    //       const docWidth = document.body.clientWidth*2
    //       if (docWidth < 1680) {
    //         const currentScale = docHeight / docWidth
    //         let [scale, translate] = [0, 0]
    //         if (currentScale < standardScale) {
    //           // 以高度计算
    //           scale = docHeight / 902
    //           const shouleWidth = 1920 * scale
    //           const offsetWidth = docWidth - shouleWidth
    //           translate = offsetWidth > 0 ? `translate(${offsetWidth / 2}px, 0)` : ''
    //         } else {
    //           // 以宽度计算
    //           scale = docWidth / 1920
    //           const shouleHeight = 902 * scale
    //           const offsetHeight = docHeight - shouleHeight
    //           translate = offsetHeight > 0 ? `translate(0, ${offsetHeight / 2}px)` : ''
    //         }
    //         console.log(translate)
    //         $app.style.cssText = `
    //         transform: scale(${scale}) ${translate};
    //         transform-origin: top left;
    //         min-width: 1920px;
    //         min-height: 1080px;
    //       `
    //       } else {
    //         $app.style.cssText = ''
    //       }
    //     }),
    //     200
    //   )
    //   if (document.createEvent) {
    //     var event = document.createEvent('HTMLEvents')
    //     event.initEvent('resize', true, true)
    //     window.dispatchEvent(event)
    //   } else if (document.createEventObject) {
    //     window.fireEvent('onresize')
    //   }
    // })
  },
};
</script>

<style lang="scss">
// 用jq适配
// body {
//   transform: scale(1, 1);
//   -ms-transform: scale(1, 1); /* IE 9 */
//   -webkit-transform: scale(1, 1); /* Safari and Chrome */
//   transform-origin: left top; /*设置左上角为缩放原点*/
// }
</style>
