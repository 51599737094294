<template>
  <div>
    <hr />
    <div class="bottom-info">
      <!-- 网站运行时间 -->
      <div class="web-time bottom-info-item">
        本站出生至今已安全存活：<span style="color:red;">{{daysDistance('2022-08-21', getToday())}}</span> 天
      </div>
      <!-- 管局备案号 -->
      <div class="beian1 bottom-info-item">渝ICP备2022008654号-1</div>
      <!-- 公网备案号 -->
      <div class="beian2 bottom-info-item">
        <img src="../images/备案图标.png" />渝公网安备 50011602500933号
      </div>
    </div>
    <!-- <div class="goTopBtn1">
      <el-backtop visibility-height='10' target='body'><img src="../images/goTop.png"></el-backtop>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "MobileTail",
  data() {
    return {};
  },
  methods: {
    getToday() {
      var today = new Date();
      var str = "";
      str += today.getFullYear() + "-";
      var month = today.getMonth() + 1; //返回值是 0（一月） 到 11（十二月） 之间的一个整数。
      if (month < 10) {
        str += "0";
      }
      str += month + "-";
      var day = today.getDate(); //返回值是 1 ~ 31 之间的一个整数
      if (day < 10) {
        str += "0";
      }
      str += day;
      return str;
    },
    daysDistance(date1, date2) {
      // parse() 是 Date 的一个静态方法 , 所以应该使用 Date.parse() 来调用，
      // 而不是作为 Date 的实例方法。返回该日期距离 1970/1/1 午夜时间的毫秒数
      date1 = Date.parse(date1);
      date2 = Date.parse(date2);
      // 计算两个日期之间相差的毫秒数的绝对值
      const ms = Math.abs(date2 - date1);
      // 毫秒数除以一天的毫秒数,就得到了天数
      const days = Math.floor(ms / (24 * 3600 * 1000));
      return days;
    },
  },
};
</script>

<style lang="scss">
.bottom-info {
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 3em;

  .bottom-info-item {
    margin-top: 5px;

    img {
      width: 1em;
      height: 1em;
      margin-bottom: -1px;
      margin-right: 5px;
    }
  }
}
// .goTopBtn1{
//   img{
//     width: 25px;
//     height: 25px;
//   }
// }
</style>