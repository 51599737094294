import Vue from 'vue'
import App from './App.vue'

// 移出默认样式
import "./style/devmain.css"



// 引入 在线聊天模块 和 vuex
import VueSocketIO from 'vue-socket.io'
import store from './store/index'

Vue.use(
  new VueSocketIO({
    // debug调试，生产建议关闭
    debug: false, 
    // connection: "http://localhost:3000",
    // connection: "http://test.kknbb.cc:3000/socket.io/?EIO=3&transport=polling&t=OFFidr8",
    connection: "https://test.kknbb.cc:3000",
    vuex: {
      store,
      actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_'
    },
    options: {     //Optional options, 
      autoConnect:false, //关闭自动连接，在用户登录后在连接。
    }
  })
);


// 引入配置可伸缩布局方案
// import 'amfe-flexible';
// import 'lib-flexible-computer'


// echarts
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'
// 手动引入 ECharts 各模块来减小打包体积

import { CanvasRenderer } from 'echarts/renderers';
import { BarChart } from 'echarts/charts';
import { GridComponent, TooltipComponent } from 'echarts/components';
import { LegendComponent } from 'echarts/components';
import { ToolboxComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { GaugeChart } from 'echarts/charts';
import { TitleComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { TreemapChart } from 'echarts/charts';

use([
  CanvasRenderer,
  BarChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  PieChart,
  GaugeChart,
  TitleComponent,
  LineChart,
  TreemapChart
]);

// 全局注册组件（也可以使用局部注册）
Vue.component('v-chart', ECharts)



// 使用网页标题组件npm install vue-wechat-title --save
import VueWechatTitle from "vue-wechat-title"
Vue.use(VueWechatTitle)



//滑动验证
import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify)



// 引入scrollReveal组件
import VueScrollReveal from 'vue-scroll-reveal';
Vue.use(VueScrollReveal, {
  class: 'v-scroll-reveal',
  duration: 800,
  scale: 1,
  distance: '500px',
  reset: false,
  mobile: true,
  useDelay: 'always',
  origin: 'left',
  delay: 100
});



// 引入markdown编辑器
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
Vue.use(mavonEditor)


// 引入vue插件包
import VueParticles from 'vue-particles'
Vue.use(VueParticles)



// 导入路由组件包
import router from './router'



// 导入element-ui组件包
import './element-ui'

// 按需导入 Loading 组件
import { Loading } from 'element-ui'



// 全局注册组件
import Header from "@/components/Header.vue"
import Tail from "@/components/Tail.vue"
import MobileHeader from "@/componentsMobile/MobileHeader.vue"
import MobileTail from "@/componentsMobile/MobileTail.vue"
Vue.component('Header', Header)
Vue.component('Tail', Tail)
Vue.component('MobileHeader', MobileHeader)
Vue.component('MobileTail', MobileTail)



// // 导入cloudbase腾讯云vue拓展包
// import Cloudbase from "@cloudbase/vue-provider";
// // 全局使用cloudbase
// Vue.use(Cloudbase, {
//   env: "lbs-blog-0gu2noulf9ec7872"
// });



// 导入axios包
import axios from "axios"
// 请求根路径
axios.defaults.baseURL = "https://kknbb.cc:3001"
// axios.defaults.baseURL = "http://localhost:3001"

// 配置请求拦截器
// 拦截全部请求
let reqNum = 0
let loadingInstance = null
axios.interceptors.request.use(config => {
  // 判断请求是否是 不需要loading的接口，如果不是，加载 LoadingBar
  let url = config.url;
  if (url.split('/').pop() !== axios.defaults.baseURL) {

    // 开启 loading 效果
    loadingInstance = Loading.service({ fullscreen: true })

    reqNum++
  }

  // 固定写法
  return config
})

// 配置响应拦截器
axios.interceptors.response.use(response => {
  reqNum--
  if (reqNum <= 0) {
    // 关闭 loading 效果
    loadingInstance.close()
  }
  return response
})

// 在vue原型对象上挂载一个自定义属性 $http
Vue.prototype.$http = axios



// 防止修改loaclstorage值
window.addEventListener('storage', (e) => {
  localStorage.removeItem("token");
  localStorage.setItem(e.key, e.oldValue)
});



Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
