<template>
  <div>
    <div id="nav-bar" :class="navShow ? 'navOn' : 'navOff'">
      <div class="head-box">
        <div class="blog-name">
          <div
            @mouseover="isTitle = 'tracking-in-expand'"
            @mouseleave="isTitle = 'otherClass'"
          >
            <span :class="isTitle">K K N B B 的 博 客</span>
          </div>
        </div>
        <div class="middle-box">
          <div class="blog-home btn-7" @click="goHome"><span>首 页</span></div>
          <div class="blog-type btn-12">
            <span>
              分 类
              <i class="el-icon-caret-bottom"></i>
            </span>
            <span>
              分 类
              <i class="el-icon-caret-bottom"></i>
            </span>
            <ul>
              <li v-for="item in typeList" :key="item.id">
                <div @click="goClassify(item.type)">
                  {{ item.type }}
                </div>
              </li>
            </ul>
          </div>
          <div class="blog-about btn-14" @click="goAboutMe">关 于/留言</div>
        </div>
        <div class="right-box">
          <div class="blog-search">
            <!-- 搜索search-box search-box-active -->
            <div
              :class="
                isSearchFocus ? 'search-box' : 'search-box search-box-active'
              "
            >
              <input
                class="search-txt"
                type="text"
                placeholder="请输入搜索内容"
                v-model.trim="searchValue"
                @input="goSearch"
                maxlength="20"
                @focus="judgeSearchFocus"
                @blur="judgeSearchFocus"
              />
              <a class="search-btn">
                <img src="../images/sousuo.png" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 为顶部导航栏占位 -->
    <div class="slot-height"></div>

    <hr />
  </div>
</template>

<script>
import bus from "../utils/eventBus.js";

export default {
  name: "Header",
  data() {
    return {
      top: "",
      navShow: true,
      typeList: [],
      isShow: false,
      isTitle: "tracking-in-expand",
      searchValue: "",
      timeout: null,
      isSearchFocus: true,
    };
  },
  methods: {
    // 获取类型列表数据
    getType() {
      this.$http
        .get("/getTypeList")
        .then((res) => {
          // console.log(res);
          this.typeList = res.data;
        })
        .catch((err) => {
          // 调用element-ui内的方法
          this.$message.error("类型列表获取失败");
          console.log(err);
        });
    },
    // 回到首页
    goHome() {
      // console.log(this)
      if (this.$route.fullPath == "/") {
        this.$router.go(0);
      } else {
        this.$router.push("/");
      }
      this.searchValue = "";
    },
    // 跳转分类页面
    goClassify(type) {
      // console.log(this.$route.query.type);
      if (this.$route.query.type == undefined) {
        this.$router.push("/typeclassify?type=" + type);
      } else if (this.$route.query.type !== type) {
        this.$router.push("/typeclassify?type=" + type);
        this.$router.go(0);
      } else {
        this.$router.go(0);
      }
      this.searchValue = "";
    },
    // 跳转关于我的页面
    goAboutMe() {
      this.$router.push("/aboutme");
      this.searchValue = "";
    },
    // 跳转搜索页面
    goSearch() {
      if (this.searchValue !== "" && this.searchValue !== this.$route.query.q) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.$router.push("/search?q=" + this.searchValue);
          // this.searchValue=""
        }, 450);
      }
      // if (this.$route.path !== "/search") {
      //   this.searchValue=""
      // }
    },
    // input获取焦点后改变布尔值从而改变class
    judgeSearchFocus() {
      // console.log(this.isSearchFocus)
      this.isSearchFocus = !this.isSearchFocus;
    },
  },
  created() {
    this.getType();
    // console.log(this.$route);
    // 获取共享数据，对input进行清空
    bus.$on("searchShare", () => {
      this.searchValue = "";
    });
    bus.$on("detailShare", () => {
      this.navShow = true;
    });
  },
  watch: {
    top(newValue, oldValue) {
      // 等新值大于100的时候再做变化（优化一下）
      if (newValue > 100) {
        if (newValue > oldValue) {
          this.navShow = false;
          // console.log("向下滚动");
        } else {
          this.navShow = true;
          // console.log("向上滚动");
        }
      }
    },
  },
  mounted() {
    // 监听鼠标滚动事件
    window.addEventListener("scroll", () => {
      this.top =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;
    });
  },
  // updated() {
  //   if (this.$route.path !== "/search") {
  //     this.timeout = setTimeout(() => {
  //       this.searchValue = "";
  //     }, 450);
  //   }
  // this.navShow = true;
  // },
};
</script>

<style lang="scss">
.slot-height {
  height: 50px;
}

.navOn {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.2s ease-in-out 0.2s;
  transform: translateZ(0);
  z-index: 1600;
}
.navOff {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.2s ease-in-out 0.2s;
  transform: translate3d(0, -100%, 0);
  z-index: 1600;
}

.head-box {
  width: 100%;
  height: 50px;
  // border-bottom: 1px solid black;
  box-shadow: 0 2px 10px gray;
  display: flex;
  flex-direction: row;
  line-height: 50px;
  background-color: rgba(0, 74, 128, 0.5);
  color: #f9f0da;
  font-size: 18px;

  .blog-name {
    width: 33.33%;
    text-align: center;

    .otherClass {
      color: #f9f0da;
    }
    .tracking-in-expand {
      color: #f9f0da;
      -webkit-animation: tracking-in-expand 2s both;
      animation: tracking-in-expand 2s both;
    }
    @-webkit-keyframes tracking-in-expand {
      0% {
        letter-spacing: -0.5em;
        opacity: 0;
      }
      40% {
        opacity: 0.6;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes tracking-in-expand {
      0% {
        letter-spacing: -0.5em;
        opacity: 0;
      }
      40% {
        opacity: 0.6;
      }
      100% {
        opacity: 1;
      }
    }
  }

  .middle-box {
    width: 33.33%;
    display: flex;
    flex-direction: row;

    .blog-home {
      //   position: absolute;
      //   right: 400px;
      border-left: 1px solid #efefef;
      border-right: 1px solid #efefef;
      width: 33.33%;
      text-align: center;
    }
    // .blog-home:hover {
    //   background-color: rgba(0, 74, 128, 1);
    //   transform: scale(1.1, 1.1);
    //   border-left: 0px;
    //   box-shadow: -2px 0 5px #efefef, 2px 0px 5px #efefef, 0px 2px 5px #efefef;
    // }
    /* 7 */
    .btn-7 {
      position: relative;
      display: block;
    }
    .btn-7 span {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
    }
    .btn-7:before,
    .btn-7:after {
      position: absolute;
      content: "";
      right: 0;
      bottom: 0;
      background: rgba(0, 74, 128, 1);
      box-shadow: -7px -7px 20px 0px rgba(255, 255, 255, 0.9),
        -4px -4px 5px 0px rgba(255, 255, 255, 0.9),
        7px 7px 20px 0px rgba(0, 0, 0, 0.2), 4px 4px 5px 0px rgba(0, 0, 0, 0.3);
      transition: all 0.3s ease;
    }
    .btn-7:before {
      height: 0%;
      width: 2px;
    }
    .btn-7:after {
      width: 0%;
      height: 2px;
    }
    .btn-7:hover {
      color: rgba(0, 74, 128, 1);
      background: transparent;
      background-color: #f9f0da;
    }
    .btn-7:hover:before {
      height: 100%;
    }
    .btn-7:hover:after {
      width: 100%;
    }
    .btn-7 span:before,
    .btn-7 span:after {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      background: rgba(0, 74, 128, 1);
      box-shadow: -7px -7px 20px 0px rgba(255, 255, 255, 0.9),
        -4px -4px 5px 0px rgba(255, 255, 255, 0.9),
        7px 7px 20px 0px rgba(0, 0, 0, 0.2), 4px 4px 5px 0px rgba(0, 0, 0, 0.3);
      transition: all 0.3s ease;
    }
    .btn-7 span:before {
      width: 2px;
      height: 0%;
    }
    .btn-7 span:after {
      height: 2px;
      width: 0%;
    }
    .btn-7 span:hover:before {
      height: 100%;
    }
    .btn-7 span:hover:after {
      width: 100%;
    }

    .blog-type {
      //   position: absolute;
      //   right: 300px;
      width: 33.33%;
      text-align: center;
      // height: auto;

      img {
        width: 20px;
        height: 20px;
        background-color: white;
      }
    }
    .btn-12 {
      position: relative;

      ul {
        position: absolute;
        top: 50px;
        width: 100%;
        // opacity: 0;
        // visibility: hidden;
        // transition: all 0.5s;
        // z-index: -999;
        display: none;

        li {
          text-align: center;
          border-bottom: 1px solid rgba(0, 74, 128, 1);
          height: 50px;
          background-color: #f9f0da;
          color: rgba(0, 74, 128, 1);
        }

        li:hover {
          background-color: rgba(0, 74, 128, 1);
          color: #f9f0da;
        }
      }
    }
    .btn-12:hover ul {
      // opacity: 1;
      // visibility: visible;
      // z-index: 1000;
      display: block;
    }
    .btn-12 span {
      display: block;
      position: absolute;
      width: 100%;
      text-align: center;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
    .btn-12 span:nth-child(1) {
      -webkit-transform: rotateX(90deg);
      -moz-transform: rotateX(90deg);
      transform: rotateX(90deg);
      -webkit-transform-origin: 50% 50% -20px;
      -moz-transform-origin: 50% 50% -20px;
      transform-origin: 50% 50% -20px;
    }
    .btn-12 span:nth-child(2) {
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      transform: rotateX(0deg);
      -webkit-transform-origin: 50% 50% -20px;
      -moz-transform-origin: 50% 50% -20px;
      transform-origin: 50% 50% -20px;
      background-color: rgba(0, 74, 128, 0);
    }
    .btn-12:hover span:nth-child(1) {
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      transform: rotateX(0deg);
      background-color: #f9f0da;
      color: rgba(0, 74, 128, 1);
    }
    .btn-12:hover span:nth-child(2) {
      -webkit-transform: rotateX(-90deg);
      -moz-transform: rotateX(-90deg);
      transform: rotateX(-90deg);
    }

    .blog-about {
      //   position: absolute;
      //   right: 200px;
      width: 33.33%;
      border-left: 1px solid #efefef;
      text-align: center;
      border-right: 1px solid #efefef;
    }
    /* 14 */
    .btn-14 {
      z-index: 1;
      position: relative;
    }
    .btn-14:after {
      position: absolute;
      content: "";
      width: 100%;
      height: 0;
      top: 0;
      left: 0;
      z-index: -1;
      background-color: #f9f0da;
      background-image: linear-gradient(315deg, #f9f0da 0%, #f9f0da 74%);
      transition: all 0.3s ease;
    }
    .btn-14:hover {
      color: #000;
      color: rgba(0, 74, 128, 1);
    }
    .btn-14:hover:after {
      top: auto;
      bottom: 0;
      height: 100%;
    }
    .btn-14:active {
      top: 2px;
    }
  }

  .right-box {
    width: 33.33%;
    text-align: center;
    position: relative;

    .blog-search {
      .search-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #f9f0da;
        height: 20px;
        border-radius: 20px;
        padding: 10px;
        display: flex;
        justify-content: center;
      }
      .search-box:hover .search-txt {
        width: 200px;
        padding: 0 6px;
      }

      // input内有字时，输入框一直打开
      .search-box-active .search-txt {
        width: 200px;
        padding: 0 6px;
      }

      .search-btn img {
        float: right;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        // background: #f9f0da;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.4s;
      }
      .search-txt {
        border: none;
        background: none;
        outline: none;
        float: left;
        padding: 0;
        color: rgba(0, 74, 128, 1);
        font-size: 16px;
        transition: 0.4s;
        line-height: 40px;
        width: 0px;
      }
    }
  }
}
</style>