import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 判断设备是否为移动端的方法
const ISMOBILE = function () {
    let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    return flag;
};

//pc端的路由
export const pcRoutes = [
    {
        path: "/",
        component: () => import('@/views/index.vue'),
        children: [
            {
                path: "",
                component: () => import('@/views/home.vue'),
                name: "home",
                meta: {
                    title: "首页|LBS私人博客"
                }
            },
            {
                path: "art/:aid",
                component: () => import("@/views/articleDetail.vue"),
                name: "article",
                props: true,
                meta: {
                    title: "文章|LBS私人博客"
                }
            },
            {
                path: "typeclassify",
                component: () => import("@/views/classify.vue"),
                name: "classify",
                meta: {
                    title: "分类|LBS私人博客"
                }
            },
            {
                path: "aboutme",
                component: () => import("@/views/aboutMe.vue"),
                name: "aboutMe",
                meta: {
                    title: "关于|LBS私人博客"
                }
            },
            {
                path: "search",
                component: () => import("@/views/search.vue"),
                name: "search",
                meta: {
                    title: "搜索|LBS私人博客"
                }
            }
        ],
    },
    {
        path: "/lbsback",
        component: () => import("@/views/backstage.vue"),
        name: "backstage",
        redirect: "/lbsback/managestatistics",
        children: [
            {
                path: "managestatistics",
                component: () => import("@/views/manageStatistics.vue"),
                name: "manageStatistics",
                meta: {
                    title: "后台|LBS私人博客"
                }
            },
            {
                path: "managecommend",
                component: () => import("@/views/manageCommend.vue"),
                name: "manageCommend",
                meta: {
                    title: "后台|LBS私人博客"
                }
            },
            {
                path: "managearticle",
                component: () => import("@/views/manageArticle.vue"),
                name: "manageArticle",
                meta: {
                    title: "后台|LBS私人博客"
                }
            },
            {
                path: "managetype",
                component: () => import("@/views/manageType.vue"),
                name: "manageType",
                meta: {
                    title: "后台|LBS私人博客"
                }
            },
            {
                path: "manageabout",
                component: () => import("@/views/manageAbout.vue"),
                name: "manageAbout",
                meta: {
                    title: "后台|LBS私人博客"
                }
            },
            {
                path: "managenotice",
                component: () => import("@/views/manageNotice.vue"),
                name: "manageNotice",
                meta: {
                    title: "后台|LBS私人博客"
                }
            },
            {
                path: "mdarticle",
                component: () => import("@/views/postArticle.vue"),
                name: "mdArticle",
                meta: {
                    title: "后台|LBS私人博客"
                }
            },
        ]
    },
    {
        path: "/login",
        component: () => import("@/views/login.vue"),
        name: "login",
        meta: {
            title: "登录|LBS私人博客"
        }
    },
    {
        path: "/404",
        component: () => import("@/views/defeat.vue"),
        name: "defeat",
        meta: {
            title: "404|LBS私人博客"
        }
    },
    {
        path: '*',//匹配未定义的路由
        redirect: '/404'
    }
]

//移动端设备路由
export const mobileRoutes = [
    {
        path: "/",
        component: () => import('@/viewsMobile/indexMobile.vue'),
        children: [
            {
                path: "",
                component: () => import('@/viewsMobile/homeMobile.vue'),
                name: "homeMobile",
                meta: {
                    title: "首页|LBS私人博客"
                }
            },
            {
                path: "art/:aid",
                component: () => import("@/viewsMobile/articleDetailMobile.vue"),
                name: "articleMobile",
                props: true,
                meta: {
                    title: "文章|LBS私人博客"
                }
            },
            {
                path: "type",
                component: () => import("@/viewsMobile/typeMobile.vue"),
                name: "typeMobile",
                meta: {
                    title: "分类|LBS私人博客"
                }
            },
            {
                path: "type/:tName",
                component: () => import("@/viewsMobile/classifyMobile.vue"),
                name: "classifyMobile",
                props: true,
                // meta: {
                //     title: "类型|LBS私人博客"
                // },
                beforeEnter: (to, from, next) => {
                    // console.log(to.params.tName)
                    to.meta.title = to.params.tName + '|LBS私人博客'
                    next()
                },
            },
            {
                path: "aboutme",
                component: () => import("@/viewsMobile/aboutMobile.vue"),
                name: "aboutMobile",
                meta: {
                    title: "关于|LBS私人博客"
                }
            },
            {
                path: "search",
                component: () => import("@/viewsMobile/searchMobile.vue"),
                name: "searchMobile",
                meta: {
                    title: "搜索|LBS私人博客"
                }
            }
        ]
    },
    {
        path: "/404",
        component: () => import("@/viewsMobile/defeatMobile.vue"),
        name: "defeatMobile",
        meta: {
            title: "404|LBS私人博客"
        }
    },
    {
        path: '*',//匹配未定义的路由
        redirect: '/404'
    }
]

// 创建路由实例
const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    fallback: false,
    // 这里判断后选择使用移动端的路由还是pc端的路由
    routes: ISMOBILE() ? mobileRoutes : pcRoutes,
    // 路由跳转定位到页面顶部
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
});


// const router = new VueRouter({
//     routes: [
//         {
//             path: "/",
//             component: () => import('@/views/index.vue'),
//             children: [
//                 {
//                     path: "",
//                     component: () => import('@/views/home.vue'),
//                     name: "home"
//                 },
//                 {
//                     path: "art/:aid",
//                     component: () => import("@/views/articleDetail.vue"),
//                     name: "article",
//                     props: true
//                 },
//                 {
//                     path: "typeclassify",
//                     component: () => import("@/views/classify.vue"),
//                     name: "classify"
//                 },
//                 {
//                     path: "aboutme",
//                     component: () => import("@/views/aboutMe.vue"),
//                     name: "aboutMe"
//                 },
//                 {
//                     path: "search",
//                     component: () => import("@/views/search.vue"),
//                     name: "search"
//                 }
//             ]
//         },
//         {
//             path: "/lbsback",
//             component: () => import("@/views/backstage.vue"),
//             name: "backstage",
//             redirect: "/lbsback/manageCommend",
//             children: [
//                 {
//                     path: "managecommend",
//                     component: () => import("@/views/manageCommend.vue"),
//                     name: "manageCommend"
//                 },
//                 {
//                     path: "managearticle",
//                     component: () => import("@/views/manageArticle.vue"),
//                     name: "manageArticle"
//                 },
//                 {
//                     path: "managetype",
//                     component: () => import("@/views/manageType.vue"),
//                     name: "manageType"
//                 },
//                 {
//                     path: "manageabout",
//                     component: () => import("@/views/manageAbout.vue"),
//                     name: "manageAbout"
//                 },
//                 {
//                     path: "managenotice",
//                     component: () => import("@/views/manageNotice.vue"),
//                     name: "manageNotice"
//                 },
//                 {
//                     path: "mdarticle",
//                     component: () => import("@/views/postArticle.vue"),
//                     name: "mdArticle"
//                 },
//             ]
//         },
//         {
//             path: "/login",
//             component: () => import("@/views/login.vue"),
//             name: "login",
//         },
//         {
//             path: "/404",
//             component: () => import("@/views/defeat.vue"),
//             name: "defeat",
//         },
//         {
//             path: '*',//匹配未定义的路由
//             redirect: '/404'
//         }
//     ],
//     // 路由跳转定位到页面顶部
//     scrollBehavior(to, from, savedPosition) {
//         return { x: 0, y: 0 }
//     }
// })

// 全局导航守卫
router.beforeEach((to, from, next) => {
    const pathArr = ['/lbsback', '/lbsback/manageCommend', '/lbsback/managearticle', '/lbsback/managetype', '/lbsback/manageabout', '/lbsback/managenotice', '/lbsback/mdarticle']
    if (pathArr.indexOf(to.path) !== -1) {
        const token = localStorage.getItem('token')
        if (token) {
            next()
        } else {
            // 使用next报错
            // next('/login')
            router.push('/login')
        }
    } else {
        next()
    }
    document.title = to.meta.title;
    var timer1, timer2, arr;
    document.addEventListener('visibilitychange', function () {
        // 用户息屏、或者切到后台运行 （离开页面） 
        if (document.visibilityState === 'hidden') {
            clearTimeout(timer1);
            // ClearInterval(timer2)
            arr = [
                '(ToT)/~~~燕子!',
                '(;﹏;)没有你',
                '(ಥ﹏ಥ)我该怎么活啊',
                '╥﹏╥燕子!',
                'X﹏X你带我走吧'
            ];
            document.title = arr[parseInt(Math.random() * 5)];
        }
        // 用户打开或回到页面 
        if (document.visibilityState === 'visible') {
            clearTimeout(timer1);
            // ClearInterval(timer2)
            arr = [
                '( ╯▽╰)欢迎回来!',
                '（´v｀）祝你早安',
                '(/≧▽≦)/祝你午安',
                '(u‿ฺu✿ฺ)祝你晚安',
                '^O^早、午、晚都安'
            ];
            document.title = arr[parseInt(Math.random() * 5)];
            timer1 = setTimeout(function () {
                document.title = to.meta.title;
            }, 2500);
        }
        next();
    });
})

export default router
