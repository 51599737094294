import Vue from 'vue'

import { Breadcrumb, BreadcrumbItem, Collapse, CollapseItem, Upload, Switch, Option, Button, Backtop, Icon, Message, Avatar, Drawer, MessageBox, Input, Popover, Table, TableColumn, Pagination, Select, Tabs, TabPane } from 'element-ui'

Vue.use(Button)
Vue.use(Backtop)
Vue.use(Icon)
Vue.use(Avatar)
Vue.use(Drawer)
Vue.use(Input)
Vue.use(Popover)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Select)
Vue.use(Option)
Vue.use(Switch)
Vue.use(Upload)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Tabs)
Vue.use(TabPane)
// 使message组件不会自动触发
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt