<template>
  <div style="height: 120px">
    <hr />
    <div class="bottom-info1">
      <!-- 网站运行时间 -->
      <div class="web-time bottom-info-item1">
        本站出生至今已安全存活：<span style="color: red">{{
          daysDistance("2022-08-21", getToday())
        }}</span>
        天
      </div>
      <!-- 管局备案号 -->
      <a
        href="https://beian.miit.gov.cn/"
        target="_blank"
        class="beian bottom-info-item1"
        >渝ICP备2022008654号-1</a
      >
      <!-- 公网备案号 -->
      <a
        href="https://beian.miit.gov.cn/"
        target="_blank"
        class="beian bottom-info-item1"
      >
        <img src="../images/备案图标.png" />渝公网安备 50011602500933号
      </a>
    </div>
    <div class="goTopBtn">
      <el-backtop><img src="../images/goTop.png" /></el-backtop>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tail",
  data() {
    return {};
  },
  methods: {
    getToday() {
      var today = new Date();
      var str = "";
      str += today.getFullYear() + "-";
      var month = today.getMonth() + 1; //返回值是 0（一月） 到 11（十二月） 之间的一个整数。
      if (month < 10) {
        str += "0";
      }
      str += month + "-";
      var day = today.getDate(); //返回值是 1 ~ 31 之间的一个整数
      if (day < 10) {
        str += "0";
      }
      str += day;
      return str;
    },
    daysDistance(date1, date2) {
      // parse() 是 Date 的一个静态方法 , 所以应该使用 Date.parse() 来调用，
      // 而不是作为 Date 的实例方法。返回该日期距离 1970/1/1 午夜时间的毫秒数
      date1 = Date.parse(date1);
      date2 = Date.parse(date2);
      // 计算两个日期之间相差的毫秒数的绝对值
      const ms = Math.abs(date2 - date1);
      // 毫秒数除以一天的毫秒数,就得到了天数
      const days = Math.floor(ms / (24 * 3600 * 1000));
      return days;
    },
  },
};
</script>

<style lang="scss">
.bottom-info1 {
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  position: relative;
  z-index: 9999;

  .bottom-info-item1 {
    margin-top: 10px;

    img {
      width: 18px;
      height: 18px;
      margin-bottom: -2px;
      margin-right: 5px;
    }
  }

  .beian{
    color: #005348;
  }

  .beian:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #FCFCD4;
  }
}
.goTopBtn {
  .el-backtop {
    background-color: rgba(0, 0, 0, 0);
  }

  img {
    width: 200px;
    height: 200px;
  }
}
</style>
